import React from 'react';

const CTA = () => (
	<section className="hero is-dark is-bold box">
		<div className="hero-body">
			<div className="container">
				<div className="content has-text-centered">
					<h1 className="title">Start making money on payments processing</h1>
					<h2 className="subtitle">Start free. Scale as you need it.</h2>
					<br />
					<a href="https://dashboard.unmerchant.com/" className="button is-light is-large">
						<strong>Create my platform</strong>
					</a>
				</div>
			</div>
		</div>
	</section>
);

export default CTA;
