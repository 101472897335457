import React from 'react';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from 'gatsby'

import { Button,Box, Column, Columns, Section } from '../components/bulma';

export default function ContentSection({ items, box }) {
	let i = 0;
	return (
		<section>
			{items?.map(({ image, title, text, slug }) => (
				<Section>
					<div className="tile is-ancestor">
						{Boolean(++i % 2) && (
							<div className="tile is-parent">
								<div className="tile is-child notification is-white-bis">
									<Section>
										<p className="title is-size-2 is-spaced">{title}</p>
										<p className="subtitle is-4">{text}</p>
{slug&&<Link to={slug}><Button>Learn mode</Button></Link>}
									</Section>
								</div>
							</div>
						)}
						<div className="tile is-parent">
							<div className="tile is-child">
								<Section>{image && <PreviewCompatibleImage imageInfo={image} />}</Section>
							</div>
						</div>
						{Boolean(i % 2) || (
							<div className="tile is-parent">
								<div className="tile is-child notification is-white-bis">
									<Section>
										<p className="title is-size-2 is-spaced">{title}</p>
										<p className="subtitle is-4">{text}</p>{slug&&<Link to={slug}><Button>Learn mode</Button></Link>}
									</Section>
								</div>
							</div>
						)}
					</div>
				</Section>
			))}
		</section>
	);
}
