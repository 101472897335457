import React from 'react';

import { Section } from '../components/bulma';

export default function Blurbs({ box, items }) {
	return (
		<Section>
			<div class="tile is-ancestor">
				{items.map((item) => (
					<div class="tile is-parent" key={item.title}>
						<article class="tile is-child box">
							<h3 class="title is-spaced">{item.title}</h3>
							<h4 class="subtitle">{item.text}</h4>
						</article>
					</div>
				))}
			</div>
		</Section>
	);
}
